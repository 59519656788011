export enum EventNames {
    APPLICATION_LAUNCHED = "applicationLaunched",
    LOGGED_IN = "loggedIn",
    LOGGED_OUT = "loggedOut",
    AUDIO_OPENED = "audioOpened",
    AUDIO_SEARCH_AUDIO_OPENED = "audioSearchAudioOpened",
    AUDIO_LIVE_STREAM = "audioLivestream",
    AUDIO_PLAYED = "audioPlayed",
    AUDIO_REDEEM_REQUESTED = "audioRedeemRequested",
    AUDIO_REDEEMED = "audioRedeemed",
    AUDIO_REDEEM_FAILED = "audioRedeemFailed",
    AUDIO_PURCHASED = "audioPurchased",
    AUDIO_PURCHASE_REQUESTED = "audioPurchaseRequested",
    AUDIO_PURCHASE_FAILED = "audioPurchaseFailed",
    AUDIO_ADDED_TO_PLAYLIST = "audioAddedToPlaylist",
    AUDIO_DELETED_FROM_PLAYLIST = "audioDeletedFromPlaylist",

    VIDEO_SEARCH_VIDEO_OPENED = "videoSearchVideoOpened",
    VIDEO_OPENED = "videoOpened",
    VIDEO_LIVE_STREAM = "videoLivestream",
    VIDEO_PLAYED = "videoPlayed",
    VIDEO_PURCHASED = "videoPurchased",
    VIDEO_PURCHASE_FAILED = "videoPurchaseFailed",
    VIDEO_PURCHASE_REQUESTED = "videoPurchaseRequested",
    VIDEO_REDEEM_REQUESTED = "videoRedeemRequested",
    VIDEO_REDEEMED = "videoRedeemed",
    VIDEO_REDEEM_FAILED = "videoRedeemFailed",

    GIFT_VIDEO_REDEEMED = "giftVideoRedeemed",
    GIFT_VIDEO_REDEEM_REQUESTED = "giftVideoRedeemRequested",
    GIFT_VIDEO_REDEEM_FAILED = "giftVideoRedeemFailed",
    GIFT_VIDEO_PURCHASED = "giftVideoPurchased",
    GIFT_VIDEO_PURCHASED_REQUESTED = "giftVideoPurchaseRequested",
    GIFT_VIDEO_PURCHASE_FAILED = "giftVideoPurchaseFailed",
    GIFT_AUDIO_REDEEM_REQUESTED = "giftAudioRedeemRequested",
    GIFT_VIDEO_SEARCH_OPENED = "giftVideoSearchVideoOpened",

    GIFT_AUDIO_REDEEMED = "giftAudioRedeemed",
    GIFT_AUDIO_REDEEM_FAILED = "giftAudioRedeemFailed",
    GIFT_AUDIO_PURCHASE_REQUESTED = "giftAudioPurchaseRequested",
    GIFT_AUDIO_PURCHASED = "giftAudioPurchased",
    GIFT_AUDIO_PURCHASE_FAILED = "giftAudioPurchaseFailed",
    GIFT_AUDIO_SEARCH_OPENED = "giftAudioSearchAudioOpened",
    GIFT_AUDIO_ADDED_TO_PLAYLIST = "giftAudioAddedToPlaylist",
    GIFT_AUDIO_DELETED_FROM_PLAYLIST = "giftAudioDeletedFromPlaylist",

    COURSE_PURCHASE_REQUESTED = "coursePurchaseRequested",
    COURSE_PURCHASED = "coursePurchased",
    COURSE_PURCHASE_FAILED = "coursePurchaseFailed",
    COURSE_REDEEM_REQUESTED = "courseRedeemRequested",
    COURSE_REDEEMED = "courseRedeemed",
    COURSE_REDEEM_FAILED = "courseRedeemFailed",
    COURSE_OPENED = "courseOpened",
    COURSE_COMPLETED = "courseCompleted",
    COURSE_STEP_OPENED = "courseStepOpened",
    COURSE_STEP_STARTED = "courseStepStarted",
    COURSE_STEP_PAUSED = "courseStepPaused",
    COURSE_STEP_RESUMED = "courseStepResumed",
    COURSE_STEP_COMPLETED = "courseStepCompleted",
    COURSE_STEP_LIVESTREAM = "courseStepLivestream",
    COURSE_STEP_QUIZ_OPENED = "courseStepQuizOpened",
    COURSE_STEP_QUIZ_COMPLETED = "courseStepQuizCompleted",
    COURSE_STEP_QUESTION_OPENED = "courseStepQuestionOpened",
    COURSE_STEP_QUESTION_COMPLETED = "courseStepQuestionCompleted",
    COURSE_STEP_DOCUMENT_VIEWED = "courseStepDocumentViewed",
    COURSE_STEP_DOWNLOAD_STARTED = "courseStepDownloadStarted",
    COURSE_STEP_DOWNLOAD_FINISHED = "courseStepDownloadFinished",
    COURSE_STEP_DOWNLOAD_FAILED = "courseStepDownloadFailed",
    COURSE_STEP_DOWNLOAD_CANCELLED = "courseStepDownloadCancelled",
    COURSE_STEP_PLAYED = "courseStepPlayed",

    GIFT_COURSE_PURCHASED = "giftCoursePurchased",
    GIFT_COURSE_PURCHASE_REQUESTED = "giftCoursePurchaseRequested",
    GIFT_COURSE_PURCHASE_FAILED = "giftCoursePurchaseFailed",
    GIFT_COURSE_REDEEMED = "giftCourseRedeemed",
    GIFT_COURSE_REDEEM_REQUESTED = "giftCourseRedeemRequested",
    GIFT_COURSE_REDEEM_FAILED = "giftCourseRedeemFailed",

    GIFT_COURSE_SENT = "giftCourseSent",
    GIFT_AUDIO_GIFT_SENT = "giftAudioGiftSent",
    GIFT_VIDEO_GIFT_SENT = "giftVideoGiftSent",

    GIFT_AUDIO_OPENED = "giftAudioOpened",
    GIFT_VIDEO_OPENED = "giftVideoOpened",
    GIFT_AUDIO_LIVESTREAM = "giftAudioLivestream",
    GIFT_VIDEO_LIVESTREAM = "giftVideoLivestream",
    GIFT_AUDIO_PLAYED = "giftAudioPlayed",
    GIFT_VIDEO_PLAYED = "giftVideoPlayed",
    GIFT_COURSE_OPENED = "giftCourseOpened",

    GIFT_COURSE_STEP_LIVESTREAM = "giftCourseStepLivestream",
    GIFT_COURSE_STEP_PAUSED = "giftCourseStepPaused",
    GIFT_COURSE_STEP_STARTED = "giftCourseStepStarted",
    GIFT_COURSE_STEP_RESUMED = "giftCourseStepResumed",
    GIFT_COURSE_STEP_COMPLETED = "giftCourseStepCompleted",
    GIFT_COURSE_STEP_OPENED = "giftCourseStepOpened",
    GIFT_COURSE_STEP_QUIZ_OPENED = "giftCourseStepQuizOpened",
    GIFT_COURSE_STEP_QUESTION_COMPLETED = "giftCourseStepQuestionCompleted",
    GIFT_COURSE_STEP_DOCUMENT_VIEWED = "giftCourseStepDocumentViewed",
    GIFT_COURSE_COMPLETED = "giftCourseCompleted",
    GIFT_COURSE_STEP_QUIZ_COMPLETED = "giftCourseStepQuizCompleted",
    GIFT_COURSE_STEP_QUESTION_OPENED = "giftCourseStepQuestionOpened",
    GIFT_COURSE_STEP_PLAYED = "giftCourseStepPlayed",

    AUDIO_PLAYLIST_PLAYED = "audioPlaylistPlayed",
    AUDIO_PLAYLIST_OPENED = "audioPlaylistOpened",
    AUDIO_PLAYLIST_ADDED = "audioPlaylistAdded",
    AUDIO_PLAYLIST_DELETED = "audioPlaylistDeleted",

    AUDIO_FAVORITE = "audioFavorite",
    VIDEO_FAVORITE = "videoFavorite",
    GIFT_AUDIO_FAVORITE = "giftAudioFavorite",
    GIFT_VIDEO_FAVORITE = "giftVideoFavorite",

    AUDIO_UNFAVORITE = "audioUnfavorite",
    VIDEO_UNFAVORITE = "videoUnfavorite",
    GIFT_AUDIO_UNFAVORITE = "giftAudioUnfavorite",
    GIFT_VIDEO_UNFAVORITE = "giftVideoUnfavorite",

    DOCUMENT_OPENED = "documentOpened",
    DOCUMENT_FAVORITE = "documentFavorite",
    DOCUMENT_UNFAVORITE = "documentUnfavorite",

    AUDIO_DOWNLOAD_STARTED = "audioDownloadStarted",
    AUDIO_DOWNLOAD_FINISHED = "audioDownloadFinished",
    AUDIO_DOWNLOAD_FAILED = "audioDownloadFailed",
    AUDIO_DOWNLOAD_CANCELLED = "audioDownloadCancelled",

    GIFT_AUDIO_DOWNLOAD_STARTED = "giftAudioDownloadStarted",
    GIFT_AUDIO_DOWNLOAD_FINISHED = "giftAudioDownloadFinished",
    GIFT_AUDIO_DOWNLOAD_FAILED = "giftAudioDownloadFailed",
    GIFT_AUDIO_DOWNLOAD_CANCELLED = "giftAudioDownloadCancelled",

    VIDEO_DOWNLOAD_STARTED = "videoDownloadStarted",
    VIDEO_DOWNLOAD_FINISHED = "videoDownloadFinished",
    VIDEO_DOWNLOAD_FAILED = "videoDownloadFailed",
    VIDEO_DOWNLOAD_CANCELLED = "videoDownloadCancelled",

    GIFT_VIDEO_DOWNLOAD_STARTED = "giftVideoDownloadStarted",
    GIFT_VIDEO_DOWNLOAD_FINISHED = "giftVideoDownloadFinished",
    GIFT_VIDEO_DOWNLOAD_FAILED = "giftVideoDownloadFailed",
    GIFT_VIDEO_DOWNLOAD_CANCELLED = "giftVideoDownloadCancelled",

    RECOMMENDATIONS_OPEN = "recommendationOpened",
    RECOMMENDATION_REDEEM_REQUESTED = "recommendationRedeemRequested",
    RECOMMENDATION_REDEEM_FAILED = "recommendationRedeemFailed",
    RECOMMENDATION_REDEEM_SUCCEEDED = "recommendationRedeemSuceeded",
    RECOMMENDATION_PURCHASE_REQUESTED = "recommendationPurchaseRequested",
    RECOMMENDATION_PURCHASE_FAILED = "recommendationPurchaseFailed",
    RECOMMENDATION_PURCHASE_SUCCEEDED = "recommendationPurchaseSuceeded",
    RECOMMENDATION_PURCHASE_CANCELLED = "recommendationPurchaseCancelled",
    RECOMMENDATION_CONFIRM_PURCHASE_CANCELLED = "recommendationConfirmPurchaseCancelled",
    RECOMMENDATION_PLAYED = "recommendationPlayed",
    RECOMMENDATION_LIVE_STREAM = "recommendationLivestream",

    CONSENT_FORM_SHOWN = "consentFormShown",
    CONSENT_FORM_ACCEPTED = "consentFormAccepted",
    CONSENT_FORM_DISMISSED = "consentFormDismissed",
    CONSENT_FORM_V2_REDIRECT = "consentFormV2Redirect",
    CONSENT_FORM_V2_ACCEPTED = "consentFormV2Accepted",
    BUNDLE_OPT_IN_REDIRECT = "bundleOptInRedirect",
    SEAMLESS_SKIPPED = "seamlessSkipped",
}

export enum EventCategories {
    GENERAL = "General",
    AUDIOS = "Audios",
    VIDEOS = "Videos",
    GIFT_VIDEOS = "GiftVideos",
    GIFT_AUDIOS = "GiftAudios",
    GIFT_COURSE = "GiftCourse",
    COURSE = "Course",
    COURSE_STEP = "CourseStep",
    GIFT_COURSE_STEP = "GiftCourseStep",
    AUDIO_PLAYLIST = "AudioPlaylist",
    DOCUMENTS = "Documents",
    RECOMMENDATIONS = "Recommendation",
}

export enum EventActions {
    APPLICATION_LAUNCHED = "Application Launched",
    LOGIN = "login",
    LOGOUT = "logout",
    OPEN = "Open",
    LIVE_STREAM = "Livestream",
    REDEEM_REQUEST = "RedeemRequest",
    REDEEM_FAILED = "RedeemFailed",
    REDEEM = "Redeem",
    PURCHASE_REQUEST = "PurchaseRequest",
    PURCHASE_FAILED = "PurchaseFailed",
    PURCHASE = "Purchase",
    SEARCH = "Search",
    GIFT_SENT = "GiftSent",
    COMPLETED = "Completed",
    STARTED = "Started",
    PAUSED = "Paused",
    RESUMED = "Resumed",
    PLAY = "Play",
    QUIZ_OPENED = "QuizOpened",
    QUIZ_COMPLETED = "QuizCompleted",
    QUESTION_OPENED = "QuestionOpened",
    QUESTION_COMPLETED = "QuestionCompleted",
    DOCUMENT_VIEWED = "DocumentViewed",
    ADDED_TO_PLAYLIST = "AddedToPlaylist",
    DELETED_FROM_PLAYLIST = "DeletedFromPlaylist",
    ADDED = "Added",
    DELETE = "Delete",
    Favorite = "Favorite",
    UnFavorite = "Unfavorite",

    DOWNLOAD_STARTED = "DownloadStarted",
    DOWNLOAD_FINISHED = "DownloadFinished",
    DOWNLOAD_CANCELLED = "DownloadCancelled",
    DOWNLOAD_FAILED = "DownloadFailed",
    OPENED = "Opened",
    REDEEM_REQUESTED = "RedeemRequested",
    REDEEM_SUCCEEDED = "RedeemSuceeded",
    PURCHASE_REQUESTED = "PurchaseRequested",
    PURCHASE_SUCCEEDED = "PurchaseSuceeded",
    PURCHASE_CANCELLED = "PurchaseCancelled",
    CONFIRM_PURCHASE_CANCELLED = "ConfirmPurchaseCancelled",
    SEAMLESS_SKIPPED = "SeamlessSkipped",
    CONSENT_FORM = "ConsentForm",
    CONSENT_FORM_V2_REDIRECT = "ConsentFormRedirected",
    CONSENT_FORM_V2_ACCEPTED = "ConsentFormV2Accepted",
    BUNDLE_OPT_IN_REDIRECT = "BundleOptInRedirected",
}

export enum EventTZ {
    EDT = "EDT",
    CDT = "CDT",
    EST = "EST",
}

export enum FirebaseConfigsKeys {
    EVENT_ACCESS_KEY_ID = "events_access_key_id",
    EVENT_ACCESS_KEY = "events_access_key",
    EVENT_REGION = "events_region",
}

export interface FirebaseEventAction {
    action: EventActions;
    tz?: EventTZ;
    category?: EventCategories;
    skuId?: string;
    receiverId?: string;
    nId?: string;
    contentTitle?: string;
    stepNId?: string;
    stepSkuId?: string;
    position?: number;
    questionId?: number;
    questionPosition?: number;
    searchQuery?: string;
    playlistId?: string;

    /**Count for number of time recommendations opened automatically */
    autoTriggerCount?: number;
    /**Number of items displayed in Recommendations list */
    displayCount?: number;
    /**Recommendations opened manually or auto*/
    source?: RecommendationSource;
    /**Comma separated Ids of Recommendations in list. Max string length is 100. */
    recIds?: string;
    /**Recommendation Id */
    recId?: string;
    /**Recommendation Redeem/Purchase failure message */
    cause?: string;
}

export type RecommendationSource = "Auto Pop Up" | "Manual";
