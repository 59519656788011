import { config } from "JS/Config";
import { AppResponse } from "JS/Types";
import { BaseService } from "../BaseService";
import { BundleOptIn } from "JS/Models/BundleOptIn";

export class BundleOptInService extends BaseService {
    async getBundleOptInStatus() {
        return (
            await this.doXHR<AppResponse<BundleOptIn>>({
                url: this.routes.server.api.bundleOptIn.getBundleOptInStatus(
                    config?.user?.memberId,
                ),
                method: "GET",
                headers: {
                    authorization: `Bearer ${config?.accessToken}`,
                },
            })
        ).data;
    }
}
