import { useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
    getIsRedirectedToBundleOptInPage,
    setIsRedirectedToBundleOptInPage,
} from "JS/Helpers/LocalStorageHelpers";
import { messaging } from "JS/Helpers/UserMessaging";
import {
    setBundleOptInLoadingState,
    setBundleOptInPopupState,
    setShowBundleOptInPopup,
    setSsoRedirectionPlace,
} from "JS/Redux/BundleOptIn";
import { useAppDispatch } from "JS/Redux/Store";
import { BundleOptInService } from "JS/Services/BundleOptIn/BundleOptInService";

export const useBundleOptIn = (skip: boolean = false) => {
    const dispatch = useAppDispatch();
    const service = new BundleOptInService();
    const { enqueueSnackbar } = useSnackbar();

    const getBundleOptInStatus = useCallback(() => {
        if (getIsRedirectedToBundleOptInPage() === "true") {
            setIsRedirectedToBundleOptInPage("false");

            dispatch(
                setBundleOptInPopupState({
                    showBundleOptInPopup: false,
                    loadingBundleOptIn: false,
                }),
            );
            return;
        }

        dispatch(
            setBundleOptInLoadingState({
                loadingBundleOptIn: true,
            }),
        );
        return service
            .getBundleOptInStatus()
            .then((res) => {
                if (!res?.response?.status) {
                    enqueueSnackbar(
                        res?.response?.message || messaging.common.error,
                        {
                            variant: "error",
                        },
                    );
                }

                const resData = res.response?.data;

                dispatch(
                    setShowBundleOptInPopup({
                        showBundleOptInPopup: !!resData?.isOptInChoiceNeeded,
                    }),
                );
                dispatch(
                    setSsoRedirectionPlace({
                        ssoRedirectionPlace: resData?.ssoRedirectionPlace,
                    }),
                );

                return resData;
            })
            .catch((err) => {
                enqueueSnackbar(messaging.common.error, {
                    variant: "error",
                });
                throw err;
            })
            .finally(() =>
                dispatch(
                    setBundleOptInLoadingState({ loadingBundleOptIn: false }),
                ),
            );
    }, []);

    useEffect(() => {
        if (!skip) getBundleOptInStatus();
    }, [skip]);

    return {
        getBundleOptInStatus,
    };
};
