import { useState, useCallback, useEffect } from "react";
import { UsersService } from "JS/Services/User";
import { ILogin, LoginResponse, SeamlessUpdateResponse, User } from "JS/Models";
import { config, encryptAndDecryptXorString } from "JS/Config";
import { isBetaEnvironment } from "JS/Helpers";
import { useGlobalMedia, useResetGlobalState } from "./Media";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";
import { useGlobalMediaEssentials } from "./MediaEssentials";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { setLoggedInUser } from "JS/Redux/User";
import { appConstants } from "JS/Helpers/Contants";
import { GiftService } from "JS/Services/Gift";
import moment from "moment";
import { EventsHelper } from "JS/Services/Events/EventsHelper";
import {
    getIsRedirectedToBundleOptInPage,
    getUserFirstName,
    getUserLastName,
    setIsRedirectedToAmmwayConsentForm,
    setIsRedirectedToBundleOptInPage,
    setLocalStorageUserName,
} from "JS/Helpers/LocalStorageHelpers";
import { loggingFirebaseService } from "JS/Services/Firebase/LoggingService";
import { captureSentryError } from "JS/Helpers/SentryHelper";
import { setAudioUpdateRequired } from "JS/Redux/Audio";
import { setBundleOptInPopupState } from "JS/Redux/BundleOptIn";

const service = new UsersService();

export default service;
export const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [loginResponse, setLoginResponse] = useState<LoginResponse>(null);

    const login = useCallback((loginData: ILogin) => {
        setLoading(true);
        setLoginResponse(null);

        return service
            .login(loginData)
            .then((val) => {
                if (val.response) {
                    setLoginResponse(val.response.data);
                }
                return val;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return {
        login,
        loginResponse,
        loading,
    };
};
export const useSeamlessUpdate = (skip: boolean = false) => {
    const [validateCondition, setvalidateCondition] = useState(false);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<SeamlessUpdateResponse>(null);
    const { softResetReduxState } = useResetGlobalState();
    const { enqueueSnackbar } = useSnackbar();
    const { lastCachedTime } = useGlobalMedia();
    const { mediaEssentials } = useGlobalMediaEssentials();
    const dispatch = useAppDispatch();

    const update = useCallback(() => {
        setLoading(true);
        setResponse(null);
        return service
            .seamlessUpdate(lastCachedTime)
            .then((val) => {
                const data = val?.data;
                setResponse(data);
                if (data?.keys) {
                    config.s3.accessKey = encryptAndDecryptXorString(
                        data.keys.key1,
                        config.s3.xor,
                    );
                    config.s3.secretAccessKey = encryptAndDecryptXorString(
                        data.keys.key2,
                        config.s3.xor,
                    );
                }

                return val;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip && mediaEssentials?.allow_seamless_update_react) {
            (async () => {
                const resp = await update();
                if (
                    mediaEssentials?.allow_rule_engine_requests &&
                    resp?.data?.audios_update === 1
                ) {
                    dispatch(setAudioUpdateRequired());
                }
                if (
                    mediaEssentials?.allow_rule_engine_requests &&
                    resp?.data?.content_update === 1
                ) {
                    setvalidateCondition(true);
                    softResetReduxState();
                }
                if (
                    isBetaEnvironment()
                        ? resp?.data?.beta_version_mismatch === 1
                        : resp?.data?.version_mismatch === 1
                ) {
                    setIsRedirectedToAmmwayConsentForm("false");
                    if (getIsRedirectedToBundleOptInPage() === "true") {
                        setIsRedirectedToBundleOptInPage("false");

                        dispatch(
                            setBundleOptInPopupState({
                                showBundleOptInPopup: false,
                                loadingBundleOptIn: false,
                            }),
                        );
                    }
                    enqueueSnackbar(messaging.seamlessUpdate.versionUpdated, {
                        variant: "info",
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                }
            })();
        }
    }, [skip]);

    return {
        update,
        response,
        loading,
        validateCondition,
    };
};

export const useGlobalLoggedInUser = () => {
    const dispatch = useAppDispatch();

    return {
        loggedInUser: useAppSelector((state) => state?.user)?.loggedInUser,
        setLoggedInUserData: (user: User) => {
            dispatch(setLoggedInUser(user));
        },
    };
};

export const useGetLatestUsername = () => {
    const giftService = new GiftService();

    const getLatestUsername = () => {
        giftService
            .getUserName()
            .then((response) => {
                if (response?.data?.response?.status) {
                    loggingFirebaseService.logRealtimeAnalysis(
                        "UsernameResponse",
                        {
                            response: JSON.stringify(response.data.response),
                        },
                        "UsernameFetch",
                    );
                    const firstName = response.data.response.data.first_name;
                    const lastName = response.data.response.data.last_name;
                    if (
                        firstName &&
                        lastName &&
                        firstName !== "undefined" &&
                        lastName !== "undefined"
                    ) {
                        setLocalStorageUserName(firstName, lastName);
                    } else {
                        const err = new Error(
                            "Received undefined username in response of API call",
                        );
                        loggingFirebaseService.logRealtimeAnalysis(
                            "ReceivedUndefinedUsernameFromApiResponse",
                            null,
                            "UsernameFetch",
                        );
                        captureSentryError(err, {
                            location: "useGetLatestUsername",
                        });
                    }
                } else {
                    loggingFirebaseService.logRealtimeAnalysis(
                        "ErrorInUsernameResponse",
                        null,
                        "UsernameFetch",
                    );
                    if (response) {
                        const err = new Error(`Received response ${response}`);
                        captureSentryError(err, {
                            location: "useGetLatestUsername",
                        });
                    }
                }
            })
            .catch((err) => {
                loggingFirebaseService.logRealtimeAnalysis(
                    "ApiError",
                    null,
                    "UsernameFetch",
                );
                captureSentryError(err, {
                    location: "useGetLatestUsername",
                });
            });
    };

    const syncUsername = () => {
        const syncTime = +localStorage.getItem(
            appConstants.localStorage.usernameSyncTime,
        );
        const have24HoursPassed =
            moment.now() - syncTime >= EventsHelper.ONE_DAY;
        const userFirstName = getUserFirstName();
        const userLastName = getUserLastName();

        if (
            !userFirstName ||
            !userLastName ||
            userFirstName === "undefined" ||
            userLastName === "undefined" ||
            have24HoursPassed
        ) {
            loggingFirebaseService.logRealtimeAnalysis(
                "Username24Hrs",
                null,
                "UsernameFetch",
            );
            getLatestUsername();
        }
    };
    return {
        getLatestUsername,
        syncUsername,
    };
};
