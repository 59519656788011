import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BundleOptInState {
    showBundleOptInPopup: boolean;
    loadingBundleOptIn: boolean;
    ssoRedirectionUrl: string;
}

const initialState = {
    showBundleOptInPopup: false,
    loadingBundleOptIn: false,
    ssoRedirectionPlace: "",
};

export const bundleOptInSlice = createSlice({
    name: "bundleOptInState",
    initialState,
    reducers: {
        setBundleOptInPopupState: (
            state,
            data: PayloadAction<{ [key: string]: boolean }>,
        ) => {
            state.showBundleOptInPopup = data.payload.showBundleOptInPopup;
            state.loadingBundleOptIn = data.payload.loadingBundleOptIn;
            return state;
        },
        setShowBundleOptInPopup: (
            state,
            data: PayloadAction<{ [key: string]: boolean }>,
        ) => {
            state.showBundleOptInPopup = data.payload.showBundleOptInPopup;
            return state;
        },
        setSsoRedirectionPlace: (
            state,
            data: PayloadAction<{ [key: string]: string }>,
        ) => {
            state.ssoRedirectionPlace = data.payload.ssoRedirectionPlace;
            return state;
        },

        setBundleOptInLoadingState: (
            state,
            data: PayloadAction<{ [key: string]: boolean }>,
        ) => {
            state.loadingBundleOptIn = data.payload.loadingBundleOptIn;
            return state;
        },
        resetRedirectionState: () => initialState,
    },
});

export const {
    setBundleOptInPopupState,
    setBundleOptInLoadingState,
    setShowBundleOptInPopup,
    resetRedirectionState,
    setSsoRedirectionPlace,
} = bundleOptInSlice.actions;
