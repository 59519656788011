export const appConstants = {
    downloadingQueue: {
        downloadingQueueLimit: 50,
    },
    firebaseError: {
        permDenied: "Permission denied",
    },
    localStorage: {
        fcmToken: "fcmToken",
        webcastUUID: "Webcast_UUID",
        refreshedOnInitialized: "refreshed_on_initialized",
        playlistMigrated: "playlistMigrated",
        loggedInUserMigrated: "loggedInUserMigrated",
        firstName: "firstName",
        lastName: "lastName",
        usernameSyncTime: "usernameSyncTime",
        encryptedTokensMigrated: "encryptedTokensMigrated",
        accessToken: "encryptedAccessToken",
        refreshToken: "encryptedRefreshToken",
        courseFirebaseToken: "encryptedCourseFirebaseToken",
        webcastsFirebaseToken: "encryptedWebcastsFirebaseToken",
        isResumeMigrated: "isResumeMigrated",
        isRedirectedToAmmwayConsentForm: "isRedirectedToAmmwayConsentForm",
        isLtdConsentFormOpened: "isLtdConsentFormOpened",
        isRedirectedToBundleOptInPage: "isRedirectedToBundleOptInPage",
        resetConsentIndexDbStatus: "resetConsentIndexDbStatus",
    },
};
export const nidControlTypes = [
    "offlinePlaylist",
    "audio",
    "video",
    "audioFavorite",
    "videoFavorite",
];

//in ms
export const PLAYER_PROGRESS_INTERVAL = 2000;
export const CONTENT_TILE_SIZE = 160;
